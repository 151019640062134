@mixin text-smallest($textAlign: center, $fontWeight: 400) {
  font-family: 'Montserrat';
  font-size: 0.75rem;
  font-weight: $fontWeight;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-align: $textAlign;
}

// TODO - go through and figure out all possible text sizes - these seem to be random
@mixin text-smaller($textAlign: center, $fontWeight: 400) {
  font-family: 'Montserrat';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $fontWeight;
  line-height: 1.2rem;
  text-align: $textAlign;
}

@mixin text-small($textAlign: center, $fontWeight: 400) {
  font-family: 'Montserrat';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: $fontWeight;
  line-height: 1.2rem;
  text-align: $textAlign;
}

@mixin text-normal($textAlign: center, $fontWeight: 400) {
  font-family: 'Montserrat';
  font-size: 1rem;
  font-style: normal;
  font-weight: $fontWeight;
  line-height: 1.2rem;
  text-align: $textAlign;
}

@mixin headline-medium($textAlign: center) {
  font-family: 'Montserrat';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: $textAlign;
}
