:root {
  // Colors
  // texts
  --colorWhite: #FFF;
  --colorTextSecondary: #C0C0C0;
  --colorTextDisabled: #87878E;
  --colorHighlight: #CEAEF0;

  --colorWhiteDisabled: rgba(255, 255, 255, 0.3);
  --colorBorder: #544C58;
  --colorDividerPrimary: rgba(255, 255, 255, 0.20);

  // backgrounds
  --colorLightGreyPurple: #544F75;
  --colorSurface: #1D1B28;
  --colorSurfacePale: rgba(255, 255, 255, 0.16);
  --colorSurfacePale2: rgba(255, 255, 255, 0.08);
  --colorSurfaceDisabled: #3E3D48;
  --colorSurfaceDark: #24222F;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: rgba(0, 0, 0, 0.64);

  --colorError: #FF5A5A;
  --colorSuccess: #6FCF97;
  --colorPending: #F9A94A;
  --colorUnresolved: #59BBC9;

  // Gradients
  --gradient-primary: linear-gradient(135deg, #D22C34 0%, #6920CB 100%);

  // Fonts
  --baseFont: 'Montserrat', sans-serif;
}
