@import './assets/styles/variables';

// Custom @font-face rules for Montserrat with a specific font-weight since the font provider does
// not offer different font weights for Monserrat.
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Regular.woff') format('woff'),
    url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
    url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
    url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  min-height: 100vh;
}

body {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--colorSurface);
  color: var(--colorWhite);
  font-family: var(--baseFont);
  overflow-x: hidden;
  
  @media (min-width: 768px) {
    justify-content: center;
    background-image: url('./assets/images/background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 120vh;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 28px;
}

// unset default button styles
button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  background: none;
  border: none;
	color: inherit;
	font: inherit;
  outline: none;
}

ul {
  list-style: none;
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 690px;
  width: 100vw;
  min-width: 330px;
  gap: 32px;
  padding: 32px;

  @media (min-width: 768px) {
    justify-content: center;
  }
}
