.cRPeEq_transaction-status {
  height: 100vh;
  text-decoration: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .cRPeEq_transaction-status {
    height: 50vh;
  }
}

.cRPeEq_transaction-status__text {
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
}

.cRPeEq_transaction-status__icon {
  width: 20px;
}

.cRPeEq_transaction-status__chevron {
  height: 20px;
  width: 20px;
  transform: rotate(270deg);
}

.cRPeEq_transaction-status__btn {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: center;
  background: none;
  border: none;
  align-items: center;
  column-gap: 1rem;
  margin: 5rem 0 0;
  padding: 0;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2rem;
  display: flex;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.f80a2eb2.woff2") format("woff2"), url("Montserrat-Regular.8e2795a5.woff") format("woff"), url("Montserrat-Regular.a32550f7.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.1ef2d841.woff2") format("woff2"), url("Montserrat-Bold.72406214.woff") format("woff"), url("Montserrat-Bold.96db3e27.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.1ef2d841.woff2") format("woff2"), url("Montserrat-Bold.72406214.woff") format("woff"), url("Montserrat-Bold.96db3e27.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: var(--colorSurface);
  color: var(--colorWhite);
  font-family: var(--baseFont);
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  body {
    background-image: url("background.877c3d09.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120vh;
    background-attachment: fixed;
    justify-content: center;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 28px;
}

button, input[type="submit"], input[type="reset"] {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

input {
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

.container {
  box-sizing: border-box;
  max-width: 690px;
  width: 100vw;
  min-width: 330px;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  display: flex;
}

@media (min-width: 768px) {
  .container {
    justify-content: center;
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.Apqpjq_partner {
  color: var(--colorTextSecondary);
  cursor: pointer;
  border-top: 1px solid #504e59;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 32px;
  font-size: .75rem;
  display: flex;
}

.Apqpjq_partner__icon {
  height: 16px;
}

.Apqpjq_partner a {
  text-decoration: none;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

._8v5EJq_column {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

._8v5EJq_row {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

._8v5EJq_label {
  display: flex;
}

._8v5EJq_input {
  background-color: var(--colorSurfaceDisabled);
  border-radius: 8px;
  padding: 0 8px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.p4UE5G_status {
  justify-content: center;
  display: flex;
}

.p4UE5G_status-link {
  color: var(--colorHighlight);
  text-align: center;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  text-decoration: none;
  display: flex;
}

.p4UE5G_status-icon {
  height: 16px;
  width: 16px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.v73WpG_content {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.v73WpG_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.v73WpG_header__title {
  font-size: 1.125rem;
  font-weight: 700;
}

.v73WpG_navigation {
  align-items: center;
  gap: 10px;
  display: flex;
}

.v73WpG_navigation__text {
  color: var(--colorHighlight);
  font-size: .75rem;
  font-weight: 700;
}

.v73WpG_navigation__icon {
  height: .625rem;
  width: .625rem;
}

.v73WpG_inputs {
  flex-direction: column;
  display: flex;
}

.v73WpG_input-group {
  grid-template-columns: 50% 50%;
  display: grid;
}

@media (min-width: 768px) {
  .v73WpG_input-group {
    position: relative;
  }
}

.v73WpG_input {
  width: 100%;
  color: var(--colorWhite);
  font-size: 1rem;
}

.v73WpG_input__subtext {
  padding: 4px 24px;
  font-size: .75rem;
}

.v73WpG_input::placeholder {
  color: var(--colorTextDisabled);
  font-size: .75rem;
}

.v73WpG_token-select {
  border: 1px solid var(--colorWhite);
  box-sizing: content-box;
  height: 20px;
  width: calc(100% - 48px);
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.v73WpG_token-select--half {
  border-right: unset;
  border-radius: 16px 0 0 16px;
}

.v73WpG_token-select--tall {
  height: 40px;
}

.v73WpG_label {
  background: var(--colorSurface);
  color: var(--colorTextSecondary);
  padding: 0 4px;
  font-size: 12px;
  position: absolute;
  top: -7px;
}

.v73WpG_token {
  align-items: center;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.v73WpG_icon {
  height: 16px;
  width: 16px;
}

.v73WpG_icon--rotated {
  transition: all .5s;
  transform: rotate(180deg);
}

.v73WpG_icon--unrotated {
  transition: all .5s;
  transform: rotate(0);
}

textarea {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  color: var(--colorTextSecondary);
  background: none;
  border: none;
  outline: none;
  font-family: inherit;
  overflow: auto;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.AC4wUq_header {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .AC4wUq_header {
    flex-wrap: wrap;
    justify-content: center;
  }

  .AC4wUq_header--secondary {
    flex-wrap: unset;
    justify-content: space-between;
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.R7w09G_actions {
  gap: 8px;
  display: flex;
}

@media (min-width: 768px) {
  .R7w09G_actions {
    width: 100%;
    justify-content: center;
  }
}

.R7w09G_actions--secondary-header {
  justify-content: end;
}

.R7w09G_icon {
  width: 32px;
  height: 32px;
}

.R7w09G_button {
  background-color: var(--colorSurfaceDark);
  width: 210px;
  color: var(--colorHighlight);
  text-align: left;
  z-index: 1;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
  margin-right: 32px;
  padding: 12px 24px;
  font-family: Montserrat;
  font-size: .9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2rem;
  display: flex;
  position: absolute;
  right: 0;
  box-shadow: 0 4px 4px #0000004d, 0 8px 12px 6px #00000026;
}

@media (min-width: 768px) {
  .R7w09G_button {
    right: unset;
  }
}

.R7w09G_button .R7w09G_icon {
  width: 20px;
  height: 20px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.T4B-5W_network-action {
  text-align: left;
  background-color: var(--colorLightGreyPurple);
  height: 48px;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 8px;
  font-family: Montserrat;
  font-size: .9rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  display: flex;
}

@media (min-width: 768px) {
  .T4B-5W_network-action {
    width: 170px;
  }
}

.T4B-5W_icon {
  width: 32px;
  height: 32px;
}

.T4B-5W_icon__placeholder {
  width: 20px;
}

.T4B-5W_left {
  background-color: var(--colorWhite);
  border-radius: 50%;
}

.T4B-5W_hide-mobile {
  display: none;
}

@media (min-width: 768px) {
  .T4B-5W_hide-mobile {
    display: unset;
  }
}

.T4B-5W_disabled {
  cursor: not-allowed;
}

.T4B-5W_disconnected {
  color: var(--colorError);
  background: var(--colorSurfaceError);
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.Yq4pzW_container {
  letter-spacing: 0;
  text-align: left;
  padding: 4px 24px;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.Yq4pzW_warning {
  color: var(--colorError);
}

.oZaUMG_reverse {
  align-self: center;
}

.qdJDyq_swap {
  display: flex;
}

.qdJDyq_swap__cta {
  width: 100%;
  max-width: 686px;
}

@media (min-width: 768px) {
  .qdJDyq_swap {
    width: unset;
  }
}

.xEopTq_modal {
  width: calc(100vw - 64px);
  background: var(--colorSurfaceDark);
  z-index: 2;
  border-radius: 32px 32px 0 0;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .xEopTq_modal {
    max-width: 345px;
    bottom: unset;
    border-radius: 32px;
    margin: 0;
    top: 50%;
    left: calc(50% - 202px);
    transform: translateY(-50%);
  }
}

.xEopTq_title {
  text-align: left;
  align-self: flex-start;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
}

.xEopTq_preview {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: flex;
}

.xEopTq_token {
  text-align: center;
  align-items: center;
  gap: 4px;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2rem;
  display: flex;
}

.xEopTq_icon {
  height: 24px;
  width: 24px;
}

.xEopTq_ctas {
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  display: grid;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.DwcCya_details {
  width: 100%;
  letter-spacing: 0;
  text-align: center;
  color: var(--colorTextSecondary);
  flex-direction: column;
  row-gap: 8px;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.DwcCya_numeric-details {
  border-top: 1px solid var(--colorDividerPrimary);
  flex-direction: column;
  gap: 8px;
  padding: 32px 0 8px;
  display: flex;
}

.DwcCya_row {
  justify-content: space-between;
  display: flex;
}

.DwcCya_row--highlight {
  color: var(--colorError);
}

.DwcCya_label {
  text-align: left;
  display: flex;
}

.DwcCya_input {
  text-align: right;
}

.DwcCya_input:focus {
  background: var(--colorSurfacePale2);
  width: 1.4rem;
  text-align: center;
  border-radius: 8px;
  margin-right: 4px;
  padding: 0 16px;
}

.DwcCya_editable-input {
  cursor: pointer;
  align-items: center;
  gap: 8px;
  display: flex;
}

.DwcCya_tooltip {
  align-items: center;
  padding-left: 4px;
  display: flex;
  position: relative;
}

.DwcCya_tooltip .DwcCya_tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: var(--colorSurfaceDark);
  letter-spacing: 0;
  text-align: left;
  color: var(--colorTextSecondary);
  z-index: 1;
  border: .1px solid #fff;
  border-radius: 6px;
  padding: 3px 6px;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 22px;
}

.DwcCya_tooltip:hover .DwcCya_tooltip-text {
  visibility: visible;
}

.DwcCya_icon {
  height: 16px;
  width: 16px;
}

.DwcCya_value {
  text-align: right;
}

.DwcCya_editable-value {
  display: flex;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

._1gYT4q_input {
  border: 1px solid var(--colorWhite);
  box-sizing: content-box;
  height: 20px;
  border-radius: 0 16px 16px 0;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

._1gYT4q_input--highlight {
  border: 1px solid var(--colorError);
  border-radius: 0 16px 16px 0;
}

._1gYT4q_label {
  background: var(--colorSurfaceDark);
  color: var(--colorTextSecondary);
  padding: 0 4px;
  font-size: 12px;
  position: absolute;
  top: -7px;
}

._1gYT4q_label--highlight {
  color: var(--colorError);
}

._1gYT4q_amount {
  min-width: 50px;
  overflow: auto;
}

._1gYT4q_amount--disabled::placeholder {
  font-size: .75rem;
}

._1gYT4q_cta__max {
  font-weight: 600;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

._1mw6na_input-group {
  width: 100%;
}

@media (min-width: 768px) {
  ._1mw6na_input-group {
    position: relative;
  }
}

._1mw6na_inputs {
  grid-template-columns: 50% 50%;
  display: grid;
}

._1mw6na_token-select {
  border: 1px solid var(--colorWhite);
  border-right: unset;
  box-sizing: content-box;
  height: 20px;
  border-radius: 16px 0 0 16px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

._1mw6na_label {
  background: var(--colorSurface);
  color: var(--colorTextSecondary);
  padding: 0 4px;
  font-size: 12px;
  position: absolute;
  top: -7px;
}

._1mw6na_token {
  align-items: center;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

._1mw6na_icon {
  height: 16px;
  width: 16px;
}

._1mw6na_icon--rotated {
  transition: all .5s;
  transform: rotate(180deg);
}

._1mw6na_icon--unrotated {
  transition: all .5s;
  transform: rotate(0);
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.XetaCa_dropdown {
  background: var(--colorSurfaceDark);
  z-index: 1;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .XetaCa_dropdown {
    left: unset;
    bottom: unset;
    width: 50%;
    border: 1px solid var(--colorWhite);
    height: unset;
    border-radius: 16px;
    top: 64px;
    overflow-y: auto;
  }
}

.XetaCa_search {
  z-index: 1;
  background-color: var(--colorSurfaceDark);
  letter-spacing: 0;
  text-align: left;
  border-bottom: .1rem solid var(--colorWhite);
  width: 100%;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  position: sticky;
  top: 40px;
}

@media (min-width: 768px) {
  .XetaCa_search {
    top: 0;
  }
}

.XetaCa_input {
  background: var(--colorSurfacePale2);
  width: calc(100% - 104px);
  background-image: url("search.d9823837.svg");
  background-position: 16px;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 24px;
  margin: 12px 24px;
  padding: 8px 16px 8px 40px;
}

.XetaCa_input::placeholder {
  color: var(--colorTextSecondary);
}

.XetaCa_virtuoso-scroller-container {
  height: calc(100vh - 105px);
  margin: 0;
  position: sticky;
  top: 105px;
}

@media (min-width: 768px) {
  .XetaCa_virtuoso-scroller-container {
    height: 280px;
  }
}

.XetaCa_token-list {
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.XetaCa_token-list:not(:last-child) {
  border-bottom: .1rem solid var(--colorWhiteDisabled);
}

.XetaCa_row {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 12px 24px;
  display: flex;
}

.XetaCa_token {
  align-items: center;
  gap: 14px;
  display: flex;
  border-top: none !important;
}

.XetaCa_icon {
  height: 1rem;
  width: 1rem;
  border-top: none !important;
}

.XetaCa_chain {
  color: var(--colorTextSecondary);
  margin-top: 4px;
  font-size: .75rem;
  line-height: .87rem;
}

.XetaCa_amount {
  letter-spacing: 0;
  text-align: right;
  color: var(--colorTextSecondary);
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5rem;
  overflow-y: auto;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.HLZ93W_moonpay {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.HLZ93W_title {
  text-align: center;
  font-family: Montserrat;
  font-size: .9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
}

.HLZ93W_link {
  color: var(--colorHighlight);
  text-align: center;
  gap: 8px;
  padding: 8px;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  text-decoration: none;
  display: flex;
}

.HLZ93W_icon {
  height: 16px;
  width: 16px;
}

.EoCBgG_spinner {
  max-height: 24px;
  max-width: 24px;
  align-self: center;
  margin: auto;
  animation-name: EoCBgG_spin;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes EoCBgG_spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.e9_Umq_content {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .e9_Umq_content {
    gap: 32px;
  }
}

.e9_Umq_container {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .e9_Umq_container {
    border: 1px solid var(--colorBorder);
    background: var(--colorSurfaceDark);
    border-radius: 32px;
    gap: 32px;
    padding: 32px;
  }
}

.e9_Umq_detail-container {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.e9_Umq_detail-container--large-spacing {
  gap: 32px;
}

.e9_Umq_header {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .e9_Umq_header {
    flex-direction: column;
  }
}

.e9_Umq_header__title {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.e9_Umq_header__title-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.e9_Umq_header__back {
  align-items: center;
  font-weight: 700;
  display: flex;
}

.e9_Umq_icon {
  height: 25px;
  width: 25px;
}

.e9_Umq_custom-address {
  gap: 8px;
  padding: 16px;
}

.e9_Umq_form {
  grid-template-columns: 1fr;
  align-items: center;
  gap: 8px;
  display: grid;
}

@media (min-width: 375px) {
  .e9_Umq_form {
    grid-template-columns: 2fr 1fr;
  }
}

.e9_Umq_label {
  color: var(--colorTextSecondary);
  font-size: .75rem;
}

.e9_Umq_input {
  min-width: 200px;
  max-width: 400px;
  color: var(--colorWhite);
  background: var(--colorSurface);
  border: 1px solid var(--colorBorder);
  border-radius: 16px;
  padding: 8px 16px;
  font-size: .8rem;
}

.e9_Umq_input__subtext {
  padding: 4px 24px;
  font-size: .75rem;
}

.e9_Umq_input::placeholder {
  color: var(--colorTextDisabled);
  font-size: .75rem;
}

.e9_Umq_cta-text {
  font-size: .8rem;
}

.e9_Umq_tx-history__list {
  flex-direction: column;
  gap: 0;
  padding: 0;
  font-size: .75rem;
  display: flex;
}

.e9_Umq_tx-history__item {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.e9_Umq_tx-history__item:not(:last-child) {
  border-bottom: .1rem solid var(--colorWhiteDisabled);
}

.e9_Umq_tx-history__item-content {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.e9_Umq_tx-history__item-date {
  color: var(--colorTextSecondary);
}

.e9_Umq_tx-history__item-amount {
  align-items: center;
  gap: 4px;
  font-size: .875rem;
  display: flex;
}

.e9_Umq_tx-history__item-icon {
  height: 1rem;
  width: 1rem;
}

.e9_Umq_tx-history__item-icon--large {
  height: 1.25rem;
  width: 1.25rem;
}

.e9_Umq_tx-history__item-status {
  align-items: center;
  gap: 4px;
  display: flex;
}

.e9_Umq_green {
  color: var(--colorSuccess);
}

.e9_Umq_yellow {
  color: var(--colorPending);
}

.e9_Umq_blue {
  color: var(--colorUnresolved);
}

.e9_Umq_red {
  color: var(--colorError);
}

.e9_Umq_status {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .e9_Umq_status {
    flex-direction: row;
    align-items: center;
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.R-1LkW_header__title {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media (min-width: 768px) {
  .R-1LkW_header__title {
    align-items: center;
    font-size: 1.5rem;
  }
}

.R-1LkW_nav {
  gap: 4px;
  font-size: .725rem;
  display: flex;
}

.R-1LkW_nav__button {
  border: solid 1px var(--colorWhite);
  border-radius: 24px;
  padding: 4px 12px;
}

@media (min-width: 768px) {
  .R-1LkW_nav__button {
    width: 100px;
  }
}

.R-1LkW_nav__button--selected {
  color: var(--colorSurface);
  background-color: var(--colorWhite);
  font-weight: 600;
}

.R-1LkW_nav__back {
  align-items: center;
  font-weight: 700;
  display: flex;
}

.qm0aYG_info-box {
  background: var(--background-white-16, #ffffff29);
  letter-spacing: 0;
  text-align: left;
  border-radius: 8px;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 16px;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.qm0aYG_icon {
  height: 16px;
  width: 16px;
  padding-top: 3px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.M3ASza_detail {
  font-size: .75rem;
  font-weight: 400;
  line-height: 18px;
}

.M3ASza_label {
  color: var(--colorTextDisabled);
}

.AewiVG_error-view {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.bDk7Fq_button {
  background: var(--gradient-primary);
  width: 100%;
  text-align: center;
  border-radius: 24px;
  padding: 12px 24px;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2rem;
}

.bDk7Fq_button:hover {
  opacity: .9;
}

.bDk7Fq_button:active {
  opacity: .8;
}

.bDk7Fq_button:disabled {
  cursor: not-allowed;
  color: var(--colorTextDisabled);
  background: var(--colorSurfaceDisabled) !important;
}

@media (min-width: 768px) {
  .bDk7Fq_narrow {
    width: unset;
  }
}

.bDk7Fq_secondary {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
}

.bDk7Fq_secondary:hover {
  opacity: .9;
}

.bDk7Fq_secondary:active {
  opacity: .8;
}

.bDk7Fq_secondary--small {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  width: min-content;
  padding: 6px 8px;
}

.bDk7Fq_secondary--small:hover {
  opacity: .9;
}

.bDk7Fq_secondary--small:active {
  opacity: .8;
}

.bDk7Fq_selection-button {
  width: 100%;
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  text-align: center;
  border-radius: 32px;
  align-items: center;
  gap: 16px;
  padding: 5px 24px 5px 5px;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  display: flex;
}

.bDk7Fq_selection-button:hover {
  background-color: #ffffff14;
}

.bDk7Fq_selection-button:active {
  background-color: #ffffff1f;
}

.bDk7Fq_icon {
  width: 40px;
  height: 40px;
  background: var(--colorWhite);
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.JDkR1G_networks {
  background-color: var(--colorSurfaceDark);
  z-index: 1;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .JDkR1G_networks {
    bottom: unset;
    top: 150px;
    right: calc(50% - 73px);
    left: unset;
    width: unset;
    border-radius: 24px;
    box-shadow: 0 4px 4px #0000004d, 0 8px 12px 6px #00000026;
  }
}

.JDkR1G_list-item {
  padding: 16px 24px;
}

@media (min-width: 768px) {
  .JDkR1G_list-item {
    width: 200px;
  }
}

.JDkR1G_network {
  width: 100%;
  gap: 8px;
  display: flex;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorPending: #f9a94a;
  --colorUnresolved: #59bbc9;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

._3zOjia_blocking-layer {
  height: 100%;
  width: 100vw;
  z-index: 1;
  background-color: var(--modalBackgroundOverlay);
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  ._3zOjia_blocking-layer {
    background-color: #0000;
  }

  ._3zOjia_blocking-layer--opaque {
    background-color: var(--modalBackgroundOverlay);
  }
}

._3zOjia_modal-content {
  z-index: 2;
  margin: -16px;
}

._3zOjia_partial-modal {
  background: var(--colorSurfaceDark);
  border: 1px solid var(--divider-primary, #fff3);
  width: calc(100% - 64px);
  border-radius: 32px 32px 0 0;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 32px 32px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  ._3zOjia_partial-modal {
    top: calc(50% - 200px);
    bottom: unset;
    width: 408px;
    z-index: 1;
    border-radius: 32px;
    padding: 32px;
    left: calc(50% - 236px);
  }
}

.aJ3SSa_header {
  background-color: var(--colorSurfaceDark);
  z-index: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: sticky;
  top: 0;
}

@media (min-width: 768px) {
  .aJ3SSa_header {
    position: unset;
    top: unset;
    text-align: center;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2rem;
  }

  .aJ3SSa_header--desktop-hidden {
    display: none;
  }
}

/*# sourceMappingURL=index.d35168a8.css.map */
